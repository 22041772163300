import React, { useEffect, useState } from "react"

import { connect } from "react-redux"

import DashboardComponent from ".."
import AllCourses from "../../AllCourses"

import * as sessionSelectors from "../../../redux/slices/session/selectors"
import { getCourseById, getOwnedCourses } from "../../../services/course"

import { Styles } from "./style"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

function KursusYangDiikuti(props) {
  const { user } = props

  const [courses, setCourses] = useState([])

  useEffect(() => {
    if (user.email) {
      getOwnedCourses().then(resp => {
        const data = resp.data?.results
        setCourses(data)
      })
    }
  }, [user])

  const updateCourses = id => {
    getCourseById(id).then(response => {
      setCourses(prev => {
        const index = prev.findIndex(p => p.id === id)
        prev[index] = response.data
        return prev
      })
    })
  }

  return (
    <DashboardComponent chosen={1}>
      <Styles className="layout">
        {courses ? (
          <AllCourses
            courses={courses}
            hidePrice
            showReview
            userID={user.id}
            updateCourses={updateCourses}
          />
        ) : (
          <h5 style={{ textAlign: "center" }}>Loading...</h5>
        )}
      </Styles>
    </DashboardComponent>
  )
}

export default withConnect(KursusYangDiikuti)
