import * as React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import KursusYangDiikuti from "../../components/Dashboard/KursusYangDiikuti"

const DashboardKelasDiikuti = () => {
  return (
    <Layout>
      <SEO title="Kelas yang diikuti" />
      <KursusYangDiikuti />
    </Layout>
  )
}

export default DashboardKelasDiikuti
